import { ACTIONS } from "../../const/Actions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Context } from "../../context/Context";
import { useContext, useEffect, useState } from "react";
import styles from "./Details.module.scss"
import { getAuth } from "../../Services/getAuth";
import { getRegister } from "../../Services/getRegister";
import { getImgAutenticacion } from "../../Services/getImgAutenticacion";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../Header/Header";
import Register from "../Modal/Details/Register";
import Auth from "../Modal/Details/Auth";
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

export default function Details({row, back, rowSelected, close}) {
  const { selectRow, setSelectRow, detailRow, setDetailRow } = useContext(Context);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("")
  const [modal, setModal] = useState(false)
  const navigate = useNavigate()
  let URL = window.location
  const urlParam = sessionStorage.getItem("tenantId")

  useEffect(() => {
    // const permiso = sessionStorage.getItem("user")
    // if(!permiso){
    //     navigate("/")
    // }
    if(URL.pathname.includes("autenticaciones")){
      (async () => {
        let authUser = await getAuth(Number(urlParam), selectRow.row.usuarioEVANId, selectRow.row.usuarioId)
        if (authUser) {
            let formatList = authUser.result.map((data, i) => ({
                id: i,
                autenticacionDistance: data.AutenticacionDistance,
                autenticacionFaR: data.AutenticacionFaR,
                autenticacionFecha: data.AutenticacionFecha !== "" ? `${new Date(data?.AutenticacionFecha).toLocaleDateString("es-ES")} ${new Date(data.AutenticacionFecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                autenticacionFrR: data.AutenticacionFrR,
                autenticacionId: data.AutenticacionId,
                autenticacionLatitud: data.AutenticacionLatitud,
                autenticacionLiveness: data.AutenticacionLiveness,
                autenticacionLivenessConfidence: data.AutenticacionLivenessConfidence,
                autenticacionLongitud: data.AutenticacionLongitud,
                autenticacionLowQuality: data.AutenticacionLowQuality,
                autenticacionModo: data.AutenticacionModo,
                autenticacionResultadoCod: data.AutenticacionResultadoCod,
                autenticacionResultadoMotivo: data.AutenticacionResultadoMotivo,
                autenticacionResultadoOK: data.AutenticacionResultadoOK === "Y" ? "MATCH" : "FAIL",
                autenticacionScore: data.AutenticacionScore,
                autenticacionScoreCalculado: data.AutenticacionScoreCalculado,
                autenticacionUFH: data.AutenticacionUFH,
                autenticacionUID: data.AutenticacionUID,
                autenticacionVersion: data.AutenticacionVersion,
                factorCod: data.FactorCod,
                factorId: data.FactorId,
                factorNombre: data.FactorNombre,
                factorVarianteCod: data.FactorVarianteCod,
                factorVarianteId: data.FactorVarianteId,
                factorVarianteNombre: data.FactorVarianteNombre,
                ignoreLiveness: data.ignoreLiveness,
                registracionId: data.RegistracionId,
                usuarioEVANId: data.UsuarioEVANId,
                UsuarioId: data.UsuarioId
            }))
            setList(formatList)
          }
      })();
    }
    else if(URL.pathname.includes("registraciones")) {
        (async () => {
            let regUser = await getRegister(Number(urlParam), selectRow.row.usuarioEVANId, selectRow.row.usuarioId)
            
console.log(regUser)
          if (regUser) {
            let formatList = regUser.result.map((data, i) => ({
                id: i,
                factorCod: data.factorCod,
                factorId: data.factorId,
                factorNombre: data.factorNombre,
                factorVarianteCod: data.factorVarianteCod,
                factorVarianteId: data.factorVarianteId,
                factorVarianteNombre: data.factorVarianteNombre,
                registracionFecha: data.registracionFecha !== "" ? `${new Date(data?.registracionFecha).toLocaleDateString("es-ES")} ${new Date(data.registracionFecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                registracionLatitud: data.registracionLatitud,
                registracionLongitud: data.registracionLongitud,
                registracionId: data.registracionId,
                registracionLowQuality: data.registracionLowQuality,
                registracionOMNIId: data.registracionOMNIId,
                registracionOMNISampleId: data.registracionOMNISampleId,
                registracionResultadoCod: data.registracionResultadoCod,
                registracionResultadoMotivo: data.registracionResultadoMotivo,
                registracionResultadoOK: data.registracionResultadoOK=== "Y" ? "MATCH" : "FAIL",
                registracionUFH: data.registracionUFH,
                registracionUID: data.registracionUID,
                registracionVersion: data.registracionVersion,
                usuarioEVANId: data.usuarioEVANId,
                usuarioId: data.usuarioId,
            }))
            setList(formatList)
          }
      })()
    }
  }, []);
  useEffect(() => { 
      if(filter.length === 0){
          if(URL.pathname.includes("autenticaciones")){
            setFilter(list.findIndex(e => e.autenticacionFecha === detailRow.row.autenticacionFecha))
          } else {
            setFilter(list.findIndex(e => e.registracionFecha === detailRow.row.registracionFecha))
          }
    }
}, [row, list]);
return (
    <>  
    <Header/>
    <div className={styles.container}>
      <header className={styles.containerTop}>
        <div className={styles.left}>
          {/* <div className={styles.back} onClick={back}>
            <ArrowBackIcon />
          </div>
            <h3>Detalles</h3> */}
        </div> 
      </header>
      {URL.pathname.includes("autenticaciones") ? <Auth index={list.findIndex(e => e.autenticacionFecha === detailRow.row.autenticacionFecha)} data={list}/> : <Register index={list.findIndex(e => e.registracionFecha === detailRow.row.registracionFecha)} data={list}/>}
    </div>
    </>
  );
}