import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const logOut = async(user, pass) => {
    let logout
    let auth = sessionStorage.getItem("auth").split(" ")[1]
    console.log(auth)
    try {
        logout = await axios.post(
            serviceURL,
            {
                service: "EVANWLogOutDo",
            },
            {
                headers: {
                    "x-api-key": `IRu#N/Uf_sy:ChN]*^_YCNCR?Gl#FVw|aoaM"tskI@M$mFX|"i.!b;{~qvbMD92`,
                    "Authorization": auth
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    sessionStorage.clear()
    return logout.data
}