// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Buttons_buttonsContainer__NPi3w {
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: end;
  width: min-content;
  position: relative;
  width: 100%;
}

.Buttons_icons__1LcTG {
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;
}

.Buttons_iconsNoFilters__YATG0 {
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ButtonsContainer/Buttons.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,oBAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,oBAAA;EACA,WAAA;AACJ","sourcesContent":[".buttonsContainer {\n    height: 4em;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    width: min-content;\n    position: relative;\n    width: 100%;\n}\n\n.icons {\n    align-items: center;\n    display: flex;\n    justify-content: end;\n    width: 100%;\n}\n\n.iconsNoFilters {\n    align-items: center;\n    display: flex;\n    justify-content: end;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `Buttons_buttonsContainer__NPi3w`,
	"icons": `Buttons_icons__1LcTG`,
	"iconsNoFilters": `Buttons_iconsNoFilters__YATG0`
};
export default ___CSS_LOADER_EXPORT___;
