import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";


const libraries = ["places"];


export default function Map(props) {
    const { lat, lng, style } = props;
    const [latitud, setLatitud] = useState(null)
    const [longitud, setLongitud] = useState(null)
    const center = {
        lat: latitud,
        lng: longitud
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc",
        // libraries: {libraries}, 
      });

    const containerStyle = {
        width: "-webkit-fill-available",
        height: "220px",
        position: "relative",
        borderRadius: "10px",
        ...style,
    };

    useEffect(() => {
        setLatitud(parseFloat(lat))
        setLongitud(parseFloat(lng))
        return () => {
            setLatitud(null)
            setLongitud(null)
        }
    }, [lat, lng])

    return (
        <>
            {isLoaded && <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}  
            >
                <Marker position={center} />
            </GoogleMap>}
        </>
    );
}
