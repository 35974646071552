import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getImgRegistracion = async(tenantId, registracionId, usuarioId) => {
    let img
    try {
        img = await axios.post(
            serviceURL,
            {
                service: "EVANRegistracionImagenGet",
                params: {
                    tenantId: tenantId,
                    usuarioId: usuarioId,
                    registracionId: registracionId,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return img.data
}