import React, { useState } from 'react'
import logo from "../../assets/EVANLOGO.svg"
import Input from '../UI/Input/Input'

import styles from "./ChangePass.module.scss"
import { Alert, Snackbar } from '@mui/material'
import { signIn } from '../../Services/singIn'
import { NewPassword } from '../../Services/getNewPass'
import { useNavigate } from 'react-router-dom'

export const ChangePass = () => {
    const [user, setUser] = useState("")
    const [passAnt, setPassAnt] = useState("")
    const [passNew, setPassNew] = useState("")
    const [repeatPass, setRepeatPass] = useState("")
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    function Regex(input) {
        let regex = /([A-Za-z0-9]+(@[A-Za-z0-9]+)+)/i;
        return regex.test(input);
    }

    const handleSubmit = async () => {
        if(passAnt === passNew || passAnt === repeatPass){
            setError({msg: "La nueva contraseña debe ser diferente a la anterior",
                status: "error"})
        } else if(!Regex(passNew) || !Regex(repeatPass)){
            setError({msg: "La contraseña debe contener al menos 1 mayuscula, una minuscula, un número y un caracter especial",
                status: "error"})
        } else if(passNew !== repeatPass){
            setError({msg: "Las nuevas contraseñas no coinciden",
                status: "error"})
        } else{
            await NewPassword(user, passAnt, passNew)
            navigate(`/`)
        }
    }
    
    const handleCloseToast = () => {
        setError(false)
    }
    return (
        <div className={styles.container}>
            <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseToast}>
                <Alert
                    autoHideDuration={5000}
                    severity={error.status}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error.msg}
                </Alert>
            </Snackbar>
            <section className={styles.blueSection}>
                <div>
                    <h1 className={styles.title}>Cambio de contraseña</h1>
                </div>
                <div className={styles.containerForm}>
                    <Input value={user} change={setUser} text="Usuario" type="text" />
                    <Input value={passAnt} change={setPassAnt} text="Contraseña anterior" type="password"/>
                    <Input value={passNew} change={setPassNew} text="Nueva contraseña" type="password" />
                    <Input value={repeatPass} change={setRepeatPass} text="Repetir nueva contraseña" type="password"/>
                    <button className={styles.btn} onClick={handleSubmit} disabled={user === "" || passAnt.length < 8 || passNew.length < 8 || repeatPass.length < 8}>
                        Ingresar
                    </button>
                </div>
            </section>
            <section className={styles.orangeSection}>
                <img className={styles.logo} src={logo} alt="Logo" />
            </section>
        </div>
    )
}
