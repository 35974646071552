// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_container__YmQNy {
  margin: 20px 30px;
}

.Table_containerFilters__CZpoE {
  display: flex;
  align-items: center;
}

.Table_containerSearch__b5YvX {
  display: flex;
  align-items: center;
  gap: 22px;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 {
  color: #06132F;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: start;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 input {
  border: none;
  border-bottom: 1px solid #E5E9F0;
}
.Table_containerSearch__b5YvX .Table_containerInput__ro\\+I6 input:focus-visible {
  outline: none;
}
.Table_containerSearch__b5YvX .Table_containerBtn__7Y8Bd {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  background-color: #ff6d00;
  color: #fff;
  border-radius: 154px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAGJ;AAFI;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAIR;AAHQ;EACI,YAAA;EACA,gCAAA;AAKZ;AAJY;EACI,aAAA;AAMhB;AAFI;EACI,oBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,yBAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;AAIR","sourcesContent":[".container{\n    margin: 20px 30px\n}\n.containerFilters{\n    display: flex;\n    align-items: center;\n}\n.containerSearch{\n    display: flex;\n    align-items: center;\n    gap: 22px;\n    .containerInput{\n        color: #06132F;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 18px;\n        text-align: start;\n        input{\n            border: none;\n            border-bottom: 1px solid #E5E9F0;\n            &:focus-visible{\n                outline: none;\n            }\n        }\n    }\n    .containerBtn{\n        display: inline-flex;\n        padding: 8px 16px;\n        justify-content: center;\n        align-items: center;\n        gap: 4px;\n        flex-shrink: 0;\n        background-color: #ff6d00;\n        color: #fff;\n        border-radius: 154px;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Table_container__YmQNy`,
	"containerFilters": `Table_containerFilters__CZpoE`,
	"containerSearch": `Table_containerSearch__b5YvX`,
	"containerInput": `Table_containerInput__ro+I6`,
	"containerBtn": `Table_containerBtn__7Y8Bd`
};
export default ___CSS_LOADER_EXPORT___;
