import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getAuth = async(tenantId, usuarioEVANId, usuarioId) => {
    let authList
    try {
        authList = await axios.post(
            serviceURL,
            {
                service: "EVANAutenticacionGet",
                params: {
                    tenantId: tenantId,
                    usuarioEVANId: usuarioEVANId,
                    usuarioId: usuarioId,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return authList.data
}