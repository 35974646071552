import React, { useState } from "react";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import logoHorizontal from "../../assets/EVANLOGOHORIZONTAL.svg"
import powered from "../../assets/poweredByEVAN.svg"

import styles from "./Header.module.scss"
import { logOut } from "../../Services/logOut";
import { useNavigate } from "react-router-dom";

export default function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate()

    const handleDrawerOpen = async () => {
        setOpenDrawer(!openDrawer);
    };

    const handleLogOut = () => {
        logOut()
        // window.location.reload()
        navigate("/")
    }

    return (
        <>
            <AppBar position="relative" className={styles.root}>
                <Toolbar
                    variant="dense"
                    className={styles.toolbar}
                >
                    <div className={styles.menu}>
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            color="inherit"
                            onClick={handleDrawerOpen}
                        >
                            <GridMenuIcon className={styles.menuIcon} />
                        </IconButton>

                        <Typography
                            className={styles.wordKey}
                            color="inherit"
                            variant="h4"
                        >
                            {/* {route?.wordKey
                                ? route.wordKey
                                : (Object.values(Routes).find(route => route.route === location.pathname))?.wordKey} */}
                        </Typography>
                    </div>
                    <section className={styles.containerTop}>
                        <div>
                            <h1 className={styles.title}>Usuarios</h1>
                            <p className={styles.direct}>Inicio/Usuarios</p>
                        </div>
                        <div>
                            <h2 className={styles.name}>Patricia</h2>
                            <div className={styles.containerLogOut} onClick={() => handleLogOut()}>
                                <LogoutRoundedIcon/>
                                <p className={styles.action}>LogOut</p>
                            </div>
                        </div>
                    </section>


                    <div className={styles.userData}>
                        {/* <p>{user?.usuarioNombres} {" "} {user?.usuarioApellidos}</p>
                        <img src={user?.imagePathPERFIL} alt="" onError={() => setUser((data) => ({ ...data, imagePathPERFIL: UserPlaceholder }))} /> */}
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                className={styles.drawer}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <div className={styles.drawerHeader}>
                    <img
                        src={logoHorizontal}
                        alt="Logo"
                        className={styles.logo}
                    />
                </div>
                <div className={styles.listContainer}>
                    {/* <ListDrawer setOpen={setOpenDrawer} /> */}
                </div>
                <div className={styles.logout} /* onClick={logout} */ >
                    {/* <img src={LogoutIcon} alt="Logout" /> */}
                    {/* Cerrar Sesión */}
                </div>
                <div className={styles.drawerFooter}>
                    <img
                        src={powered}
                        alt="Powered by MIA"
                    />
                </div>
            </Drawer>
            {openDrawer && (
                <div
                    className={styles.drawerHandler}
                    onClick={() => setOpenDrawer(false)}
                />
            )}
        </>
    );
}
