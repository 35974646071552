import { CircularProgress } from "@mui/material";
import { getImgRegistracion } from "../../../Services/getImgRegistracion";
import styles from "./Register.module.scss"
import { useContext, useEffect, useState } from "react";
import { Card } from "../../UI/Card/Card";
import Modal from "../Modal";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from "react-router-dom";
import Map from "../../Map/Map";


export default function Register({index, data, tenantId}) {
  const [load, setLoad] = useState(false)
  const [img, setImg] = useState("")
  const [modal, setModal] = useState(false) 
  const [indexSelect, setIndexSelect] = useState(index)
  const navigate = useNavigate()
  const urlParam = sessionStorage.getItem("tenantId")

  const handleSumIndex = () => {
    if(indexSelect < data.length-1){
      setIndexSelect(indexSelect+1)

    }
  }

  const handleRestIndex = () => {
    if(indexSelect !== 0){
      setIndexSelect(indexSelect-1)
    }
  }
  useEffect(() => {
    setIndexSelect(index)
  }, [index])
  useEffect(() => {
    (async () => {
      if(data) {
        let img = await getImgRegistracion(Number(urlParam), data[indexSelect]?.registracionId, data[indexSelect]?.usuarioId) 
        setImg(img)
        }
      }
    )()
    data !== undefined ? setLoad(true) : setLoad(false)
  }, [data, indexSelect])
  console.log(data[indexSelect])
  return (
    <>
    {modal && <Modal data={data[indexSelect]} close={() => setModal(false)}/>}
    <div className={styles.container}>
      <div className={styles.containerBack} onClick={() => navigate("/usuarios/registraciones")}><ArrowBackIosNewRoundedIcon/><p>VOLVER A REGISTRACIONES</p></div>
      {load ? 
      <>
      <div className={styles.containerEvan}>
            <h2>EVAN ID: </h2>
            <h3>{data[indexSelect]?.usuarioEVANId}</h3>
        </div>
      <div className={styles.containerSection}>
        <div className={styles.containerPrincipal}>
          {img !== "" && <img src={img?.result[0]?.registracionImagenURL} className={styles.img} alt="imagen registracion"/> }
          <div className={styles.containerPrincipalData}>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Registración ID:</p>
              <p className={`${styles.text} ${styles.big}`}>{data[indexSelect]?.usuarioEVANId}</p>
            </div>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Fecha:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.registracionFecha.split(" ")[0]}</p>
            </div>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Hora:</p>
              <p className={`${styles.text}`}>{`${data[indexSelect]?.registracionFecha.split(" ")[1]} hs`}</p>
            </div>
          </div>
          <div className={styles.containerCards}>
            <Card principal={{title: "", result: ""}} second={{title:"", result:""}}/>
            <Card livenness openModal={() => setModal(true)} />
            <Card principal={{title: "", result: ""}} />
          </div>
        </div>
        <div className={styles.containerBottom}>
          <div className={styles.left}>
            <div className={styles.containerTitle}>
              <p>Factor de registración</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Factor nombre:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.factorNombre}</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Factor variante:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.factorVarianteNombre}</p>
            </div>
            <div className={styles.containerTitle}>
              <p>Data autenticador</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Internal sample ID:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.registracionOMNISampleId}</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Internal ID:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.registracionOMNIId}</p>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.containerTitle}>
              <p>Geolocalización</p>
            </div>
            <div className={styles.contLat}>
              <div>
                <div className={styles.containerData}>
                  <p className={`${styles.text} ${styles.bold}`}>Latitud:</p>
                  <p className={`${styles.text}`}>{data[indexSelect]?.registracionLatitud}</p>
                </div>
                <div className={styles.containerData}>
                  <p className={`${styles.text} ${styles.bold}`}>Longitud:</p>
                  <p className={`${styles.text}`}>{data[indexSelect]?.registracionLongitud}</p>
                </div>
              </div>
              <Map lat={data[indexSelect]?.registracionLatitud} lng={data[indexSelect]?.registracionLongitud}/>
            </div>
          </div>
        </div>
        <div className={styles.containerBtn}>
          <button disabled={indexSelect === 0 ? true : false} onClick={handleRestIndex}>
            Anterior
          </button>
          <button disabled={indexSelect === data.length-1} onClick={handleSumIndex}>
            Siguiente
          </button>
        </div>
      </div>
      </> : <CircularProgress />}
    </div>
    </>
  );
}