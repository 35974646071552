import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getUser = async(tenantId, user, page, pageSize) => {
    let userList
    try {
        userList = await axios.post(
            serviceURL,
            {
                service: "EVANUsuarioResumenGet",
                params: {
                    tenantId: tenantId,
                    usuarioEVANId: user || null,
                    page: page || 1,
                    pageSize: pageSize,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return userList.data
}