import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const signIn = async(user, pass) => {
    let login
    try {
        login = await axios.post(
            serviceURL,
            {
                service: "EVANWLogInDo",
                params: {
                    internoUsuario: user,
                    internoPassword: pass,
                }
            },
            {
                headers: {
                    "x-api-key": `IRu#N/Uf_sy:ChN]*^_YCNCR?Gl#FVw|aoaM"tskI@M$mFX|"i.!b;{~qvbMD92`
                },
            }
            
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return login
}