import { useContext, useEffect, useState } from "react";
import { ACTIONS } from "../../const/Actions";
import HeaderButton from "../UI/Button/HeaderButton";
import Buttons from "../UI/ButtonsContainer/Buttons";
import XGrid from "../UI/Grid/XGrid/XGrid";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import { Context } from "../../context/Context";
import { getAuth } from "../../Services/getAuth";
import { colsAuth } from "../../const/Columns";
import styles from "./Autenticaciones.module.scss"
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export default function Autenticaciones({row, setRow, rowDetail, close}) {
    const {selectRow, setSelectRow, detailRow, setDetailRow} = useContext(Context);
    const [list, setList] = useState([]);
    const navigate = useNavigate()
    const urlParam = sessionStorage.getItem("tenantId")
    
    useEffect(() => {
        // const permiso = sessionStorage.getItem("user")
        // if(!permiso){
        //     navigate("/")
        // }
        // if(!selectRow.status){
        //     navigate("/usuarios")
        // }
        (async () => {
            if(selectRow.status === true){
                let authUser = await getAuth(Number(urlParam), selectRow.row.usuarioEVANId, selectRow.row.usuarioId)
                    if (authUser) {
                        let formatList = authUser.result.map((data, i) => ({
                            id: i+1,
                            autenticacionId: data.autenticacionId,
                            registracionId: data.registracionId,
                            autenticacionFecha: data.autenticacionFecha !== "" ? `${new Date(data?.autenticacionFecha).toLocaleDateString("es-ES")} ${new Date(data.autenticacionFecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                            autenticacionModo: data.factorVarianteNombre,
                            factorNombre: data.factorNombre,
                            autenticacionResultadoOK: data.autenticacionResultadoOK === "Y" ? "Match" : "Error"  ,
                            autenticacionScoreCalculado: data.autenticacionScoreCalculado?.toFixed(4),
                            autenticacionFaR: data.autenticacionFaR?.toFixed(4),
                            autenticacionFrR: data.autenticacionFrR?.toFixed(4),
                            autenticacionLiveness: data.ignoreLiveness === "Y" ? "N/A" : data.AutenticacionLiveness === "Y" ? "Match" : "Error" ,
                            autenticacionLivenessConfidence: data.autenticacionLivenessConfidence?.toFixed(4),
                        }))
                        setList(formatList)
                    }
            }
            
        })();
    }, []);
return (
    <>
    <Header/>
    <div className={styles.container}>
    <div className={styles.containerBack} onClick={() => navigate("/usuarios")}><ArrowBackIosNewRoundedIcon/><p>VOLVER A USUARIOS</p></div>
        <div className={styles.containerTop}>  
        <div>
            <h2>EVAN ID: </h2>
            <h3>{selectRow.row.usuarioEVANId}</h3>
        </div>
        <Buttons filtersApplied={true}>
            <HeaderButton
                disabled={rowDetail !== 0 ? false : true}
                text={"Detalles"}
                link={`detalles${window.location.search}`}
                data={{
                    action: ACTIONS.DETAILSAUTH,
                    title: "Detalles",
                    button: "Detalles",
                }}
            >
                <ListAltRoundedIcon />
            </HeaderButton>
        </Buttons>
        </div>
        <XGrid columns={colsAuth} list={list} setRow={setDetailRow} rowSelect={detailRow}/>
    </div>
    </>
  );
}