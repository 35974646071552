import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';

export default function XGrid({list, setRow = () => {}, columns, rowSelect}) {

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={list || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 6 },
          },
        }}
        disableColumnMenu
        pageSizeOptions={[5, 10]}
        onRowClick={(data) => {
          if (data.row.id === rowSelect?.row.id) {
            setRow({ status: false, row: {} })
              return;
          }
          setRow({ status: true, row: data.row })
        }}
        rowSelectionModel={rowSelect?.row.id || [] }
      />
    </div>
  );
}