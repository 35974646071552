import { CircularProgress } from "@mui/material";
import { getImgAutenticacion } from "../../../Services/getImgAutenticacion";
import styles from "./Auth.module.scss"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../UI/Card/Card";
import Modal from "../Modal";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Map from "../../Map/Map";

export default function Auth({index, data, tenantId}) {
  const [load, setLoad] = useState(false)
  const [img, setImg] = useState("")
  const [indexSelect, setIndexSelect] = useState(index)
  const [modal, setModal] = useState(false) 
  const navigate = useNavigate()
  const urlParam = sessionStorage.getItem("tenantId")

  const handleSumIndex = () => {
    if(indexSelect < data.length-1){
      setIndexSelect(indexSelect+1)

    }
  }

  const handleRestIndex = () => {
    if(indexSelect !== 0){
      setIndexSelect(indexSelect-1)
    }
  }
  useEffect(() => {
    setIndexSelect(index)
  }, [index])
  useEffect(() => {
    (async () => {
      if(data) {
        let img = await getImgAutenticacion(Number(urlParam), data[indexSelect]?.autenticacionId, data[indexSelect]?.UsuarioId) 
        setImg(img)
        }
      }
    )()
    data !== undefined ? setLoad(true) : setLoad(false)
  }, [data, indexSelect])
  console.log(data[indexSelect])
  return (
    <>
    {modal && <Modal data={data[indexSelect]} close={() => setModal(false)}/>}
    <div className={styles.container}>
      <div className={styles.containerBack} onClick={() => navigate("/usuarios/autenticacion")}><ArrowBackIosNewRoundedIcon/><p>VOLVER A AUTENTICACIONES</p></div>
      {load ? 
      <>
      <div className={styles.containerEvan}>
            <h2>EVAN ID: </h2>
            <h3>{data[indexSelect]?.usuarioEVANId}</h3>
        </div>
      <div className={styles.containerSection}>
        <div className={styles.containerPrincipal}>
          {img !== "" && <img src={img?.result[0]?.autenticacionImagenURL} className={styles.img} alt="imagen autenticacion"/> }
          <div className={styles.containerPrincipalData}>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Autenticación ID:</p>
              <p className={`${styles.text} ${styles.big}`}>{data[indexSelect]?.autenticacionId}</p>
            </div>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Fecha:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionFecha.split(" ")[0]}</p>
            </div>
            <div className={styles.containerData}>
              <p className={`${styles.text} ${styles.bold}`}>Hora:</p>
              <p className={`${styles.text}`}>{`${data[indexSelect]?.autenticacionFecha.split(" ")[1]} hs`}</p>
            </div>
          </div>
          <div className={styles.containerCards}>
            <Card principal={{title: "Resultado", result: data[indexSelect]?.autenticacionResultadoOK}} second={{title:"Score", result:data[indexSelect]?.autenticacionResultadoCod}} />
            <Card livenness openModal={() => setModal(true)} principal={{title: "Livenness", result: data[indexSelect]?.ignoreLiveness === "Y" ? "N/A" : data[indexSelect]?.autenticacionLiveness === "Y" ? "Match" : "Error"}} second={{title:"Confidence", result:data[indexSelect]?.autenticacionLivenessConfidence?.toFixed(4)}}/>
            <Card principal={{title: "Autenticador", result: "Activo"}} />
          </div>
        </div>
        <div className={styles.containerBottom}>
          <div className={styles.left}>
            <div className={styles.containerTitle}>
              <p>Factor de registración</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Nombre:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.factorNombre}</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Variante:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.factorVarianteNombre}</p>
            </div>
            <div className={styles.containerTitle}>
              <p>Data autenticador</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Registración ID:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.registracionId}</p>
            </div>
            <div className={styles.containerDataHorizontal}>
              <p className={`${styles.text} ${styles.bold}`}>Registración fecha:</p>
              <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionOMNIId}</p>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.containerTitle}>
              <p>Geolocalización</p>
            </div>
            <div className={styles.contLat}>
              <div>
                <div className={styles.containerData}>
                  <p className={`${styles.text} ${styles.bold}`}>Latitud:</p>
                  <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionLatitud}</p>
                </div>
                <div className={styles.containerData}>
                  <p className={`${styles.text} ${styles.bold}`}>Longitud:</p>
                  <p className={`${styles.text}`}>{data[indexSelect]?.autenticacionLongitud}</p>
                </div>
              </div>
              <Map lat={data[indexSelect]?.autenticacionLatitud} lng={data[indexSelect]?.autenticacionLongitud}/>
            </div>
          </div>
        </div>
        <div className={styles.containerBtn}>
          <button disabled={indexSelect === 0 ? true : false} onClick={handleRestIndex}>
            Anterior
          </button>
          <button disabled={indexSelect === data.length-1} onClick={handleSumIndex}>
            Siguiente
          </button>
        </div>
      </div>
      </> : <CircularProgress />}
    </div>
    </>
  );
}