import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getRegister = async(tenantId, usuarioEVANId, usuarioId) => {
    let registerList
    try {
        registerList = await axios.post(
            serviceURL,
            {
                service: "EVANRegistracionGet",
                params: {
                    tenantId: tenantId,
                    usuarioEVANId: usuarioEVANId,
                    usuarioId: usuarioId,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return registerList.data
}