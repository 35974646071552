import { useContext, useEffect, useState } from "react";
import { ACTIONS } from "../../const/Actions";
import HeaderButton from "../UI/Button/HeaderButton";
import Buttons from "../UI/ButtonsContainer/Buttons";
import XGrid from "../UI/Grid/XGrid/XGrid";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import { Context } from "../../context/Context";
import { getRegister } from "../../Services/getRegister";
import { colsReg } from "../../const/Columns";
import styles from "./Registraciones.module.scss"
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

export default function Registraciones({row, setRow, rowDetail, close}) {
    const {selectRow, setSelectRow, detailRow, setDetailRow} = useContext(Context);
    const [list, setList] = useState([]);
    const [rowSelect, setRowSelect] = useState(0)
    const navigate = useNavigate()
    const urlParam = sessionStorage.getItem("tenantId")
    
    useEffect(() => {
        // const permiso = sessionStorage.getItem("user")
        // if(!permiso){
        //     navigate("/")
        // }
        // if(!selectRow.status){
        //     navigate("/usuarios")
        // }
        (async () => {
            if(selectRow.status === true){
              let regUser = await getRegister(Number(urlParam), selectRow.row.usuarioEVANId, selectRow.row.usuarioId)
              if (regUser) {
                  let formatList = regUser.result.map((data, i) => ({
                      id: i+1,
                      registracionId: data.registracionId,
                      registracionFecha: data.registracionFecha !== "" ? `${new Date(data?.registracionFecha).toLocaleDateString("es-ES")} ${new Date(data.registracionFecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}` : "-",
                      factorNombre: data.factorNombre,
                      registracionResultadoOK: data.registracionResultadoOK=== "Y" ? "Match" : "Error"  ,
                      registracionResultadoCod: data.registracionResultadoCod,
                      registracionResultadoMotivo: data.registracionResultadoMotivo,
                      registracionLowQuality: data.registracionLowQuality,
                      registracionLatitud: data.registracionLatitud,
                      registracionLongitud: data.registracionLongitud,
                  }))
                  setList(formatList)
              }
            }
        })();
    }, []);
return (
    <>
    <Header/>
    <div className={styles.container}>
    <div className={styles.containerBack} onClick={() => navigate("/usuarios")}><ArrowBackIosNewRoundedIcon/><p>VOLVER A USUARIOS</p></div>
        <div className={styles.containerTop}>  
        <div>
            <h2>EVAN ID: </h2>
            <h3>{selectRow.row.usuarioEVANId}</h3>
        </div>
        <Buttons filtersApplied={true}>
            <HeaderButton
                disabled={detailRow.status !== false ? false : true}
                text={"Detalles"}
                link={`detalles${window.location.search}`}
                data={{
                    action: ACTIONS.DETAILSAUTH,
                    title: "Detalles",
                    button: "Detalles",
                }}
            >
                <ListAltRoundedIcon />
            </HeaderButton>
        </Buttons>
        </div>
        <XGrid columns={colsReg} list={list} setRow={setDetailRow} rowSelect={detailRow}/>
    </div>
    </>
  );
}