import { useState } from "react";
import styles from "./Input.module.scss"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function Input({value, change, text, type}) {
  const [visible, setVisible] = useState(false) 
  return (
    <div className={styles.container}>
        <label className={styles.label}>{text}</label>
        <input className={styles.input} value={value} type={visible ? "text" : type} onChange={(e) => change(e.target.value)}/>
        {type === "password" ? <span className={styles.containerImg} onClick={() => setVisible(!visible)}>{visible ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon />}</span> : null}
    </div>
  );
}