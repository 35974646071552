import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const NewPassword = async(user, passAnt, passNew) => {
    let newPass
    try {
        newPass = await axios.post(
            serviceURL,
            {
                service: "EVANWPassUpd",
                params: {
                    internoUsuario: user,
                    internoPassword: passAnt,
                    internoNPassword: passNew
        }
            },
            {
                headers: {
                    "x-api-key": `IRu#N/Uf_sy:ChN]*^_YCNCR?Gl#FVw|aoaM"tskI@M$mFX|"i.!b;{~qvbMD92`
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return newPass.data
}