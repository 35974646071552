import axios from "axios";
import { serviceURL } from "../const/ServiceURL";

export const getImgAutenticacion = async(tenantId, autenticacionId, usuarioId) => {
    let img
    try {
        img = await axios.post(
            serviceURL,
            {
                service: "EVANAutenticacionImagenGet",
                params: {
                    tenantId: tenantId,
                    usuarioId: usuarioId,
                    autenticacionId: autenticacionId,
                },
            }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    return img.data
}