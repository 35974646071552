import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styles from "./Modal.module.scss"

export default function Modal({data, close}) {
    
return (
  <div className={styles.containerModal}>
    <div className={styles.modal}>
      <div className={styles.close} onClick={close}><CloseRoundedIcon/></div>
      <div className={styles.containerTitle}>
        <h2 className={styles.title}>Data Liveness</h2>
      </div>
      <div className={styles.containerData}>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
        <div>
          <p className={`${styles.text} ${styles.bold}`}>Livnness score:</p>
          <p className={styles.text}>01010100110</p>
        </div>
      </div>
    </div>
  </div>
  );
}