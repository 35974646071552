import { useState } from "react";
import Input from "../UI/Input/Input";
import styles from "./SignIn.module.scss"
import { redirect, useNavigate } from "react-router-dom";
import logo from "../../assets/EVANLOGO.svg"
import { signIn } from "../../Services/singIn";
import { Alert, Snackbar } from "@mui/material";

export default function SignIn({storage, setStorage}) {
  const [user, setUser] = useState("")
  const [pass, setPass] = useState("")
  const [selectTenant, setSelectTenant] = useState(false)
  const [tenantId, setTenantId] = useState("")
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  let [usuario, setUsuario] = useState({})

  function Regex(input) {
    let regex = /([A-Za-z0-9]+(@[A-Za-z0-9]+)+)/i;
    return regex.test(input);
}
const handleSubmitTenant = () => {
  sessionStorage.setItem("tenantId", tenantId)
  navigate(`/usuarios`)
}
  const handleSubmit = async() => {
    if(user !== "" && pass.length >= 8){
      if(Regex(pass)){
        const response = await signIn(user, pass)
        console.log(response)
        setUsuario(response.data)
        if(response.data.status.code === 1){
          if(response?.data?.result?.length > 1){
            setSelectTenant(true)
            sessionStorage.setItem("auth", response?.headers?.authorization)
          } else {
            sessionStorage.setItem("tenantId", response?.data?.result[0]?.tenantId)
            sessionStorage.setItem("auth", response?.headers?.authorization)
            navigate(`/usuarios`)
          }
        }
        if(response.data.status.code === 0){
          if(response.data.status.action === "W"){
            setError({msg: response.data?.status.errmsg,
                      status: "warning"})
          }
          if(response.data.status.action === "X" && response.data.status.errmsg.includes("Debe aguardar")){
            setError({msg: response.data?.status.errmsg,
              status: "error"})
          }
          if(response.data.status.action === "X" && response.data.status.errmsg === "Debe cambiar el password"){
            setError({msg: response.data.status.errmsg,
              status: "error"})
            navigate(`/cambio-de-password`)
          }
        }
      } else{
        setError({msg: "La contraseña no cumple los requisitos",
          status: "error"})
      }
    } 
  }
  const handleCloseToast = () => {
    setError(false)
  }
  return (
    <div className={styles.container}>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseToast}>
        <Alert
          autoHideDuration={5000}
          severity={error.status}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error.msg}
        </Alert>
      </Snackbar>
      {!selectTenant ? <section className={styles.blueSection}>
        <div>
          <h1 className={styles.title}>Te damos la bienvenida a EVAN</h1>
          <h2 className={styles.subtitle}>Toda la información en un solo lugar</h2>
        </div>
        <div className={styles.containerForm}>
          <Input value={user} change={setUser} text="Usuario" type="text" />
          <Input value={pass} change={setPass} text="Contraseña" type="password"/>
          <button className={styles.btn} onClick={handleSubmit} disabled={pass.length < 8 || user === ""}>
            Ingresar
          </button>
        </div>
      </section> : <section className={styles.blueSection}>
        <div>
          <h1 className={styles.title}>Te damos la bienvenida a EVAN</h1>
          <h2 className={styles.subtitle}>Toda la información en un solo lugar</h2>
        </div>
        <select name="tenantId" onChange={(e) => setTenantId(e.target.value)}>
          {usuario?.result?.map((e, i) => (
            <option value={e.tenantId} key={i}>{e.tenantNombre}</option>
          ))}
        </select>
          <button className={styles.btn} onClick={handleSubmitTenant} disabled={tenantId ===""}>
            Ingresar a este tenant
          </button>
        </section>}
      <section className={styles.orangeSection}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </section>
    </div>
  );
}