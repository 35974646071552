import React from 'react'

import styles from "./Card.module.scss"
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

export const Card = ({livenness, openModal, principal, second}) => {
  return (
    <div className={styles.container}>
        <div className={`${styles.containerTop} ${principal?.result === "N/A" ? styles.not : principal?.result === "Error" ? styles.error :styles.success}`}>
            <div className={styles.containerResult}>
                <p className={styles.title}>{principal?.title || "Resultado"}</p>
                <p className={styles.result}>{principal?.result || "Match"}</p>
            </div>
            <div className={styles.containerIcon}>
                {principal?.result === "N/A" ? <RemoveRoundedIcon/> : principal?.result === "Error" ? <CloseRoundedIcon/> : <CheckRoundedIcon/>}
            </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.containerDown}>
            <div className={styles.data}>
                <p className={styles.title}>{second?.title || ""}</p>
                <p className={styles.point}>{second?.result || ""}</p>
            </div>
        </div>
        {livenness && <p className={styles.more} onClick={openModal}>Ver mas</p>}
    </div>
  )
}
